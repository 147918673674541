<template>
  <div class="home">
    <marquee-text class="marquee" :repeat="10" :duration="3">
      <!-- <span>Radio Safari&nbsp;</span> -->
      <img src="../assets/radiosafari-logo.svg" alt="" />
    </marquee-text>

    <audio loop autoplay>
      <source src="/bg_rs.mp3" type="audio/mp3" />
    </audio>
    <div class="lang">
      <div class="butt mute" v-if="mute" @click="Play">AUDIO ON</div>
      <div class="butt mute" v-else @click="Play">AUDIO OFF</div>
      <div class="butt" v-if="eng" @click="eng = !eng">ITA</div>
      <div class="butt" v-else @click="eng = !eng">ENG</div>
    </div>
    <div class="container">
      <div class="about">
        <p v-if="eng">
          <strong>Radio Safari</strong> is a series of audio-documentaries about
          animals and their sounds. <br /><br />
          Episode by episode we discover the calls and songs of animals
          inhabiting a unique global location, exploring the reasons why an
          animal produces a specific sound, from an evolutionary and anatomical
          purposive. <br /><br />
          We also explore the purpose of sound for different species and how
          languages develop and are expressed in non-human worlds.
        </p>
        <p v-else>
          <strong>Radio Safari</strong> è una serie di audio-documentari sugli
          animali e su quello che, degli animali, sentiamo. <br /><br />
          In ogni puntata andiamo in esplorazione di luoghi speciali, a scoprire
          i suoni degli animali che li abitano.<br /><br />
          Perché da un punto di vista evolutivo e anatomico un animale produce
          quel suono? <br />
          Quale scopo ha il suono per quella specie?<br />
          Come si sviluppa un linguaggio al di fuori dell’homo sapiens?
        </p>
      </div>
      <div class="img desk"></div>
      <div class="sub">
        <div v-if="!success">
          <h4 v-if="eng">JOIN THE NEWSLETTER TO GET THE LATEST PODCASTS</h4>
          <h4 v-else>
            UNISCITI ALLA NEWSLETTER PER ASCOLTARE L'ULTIMO EPISODIO
          </h4>
          <!-- <mailchimp-subscribe
            url="https://radiosafari.us7.list-manage.com/subscribe/post-json"
            user-id="adcc73703af6db6e9581dcb88"
            list-id="eb69b87b08"
            @success="Success"
          >
            <template v-slot="{ subscribe, setEmail, error, loading }"> -->
              <form @submit.prevent="subscribe">
                <input type="email" v-model="email" />
                <button v-if="eng" type="submit">SUBSCRIBE</button>
                <button v-else type="submit">ISCRIVITI</button>
                <div v-if="loading">Loading…</div>
                <div v-if="error" style="margin-top:1rem"><span v-html="error"></span></div>
              </form>
            <!-- </template>
          </mailchimp-subscribe> -->
        </div>
        <div>
          <h3 v-if="success && !eng" class="success">
            Benvenuto! La tua avventura è già iniziata, <br /><br />
            Assicurati di controllare bene la tua casella di posta (come il tab
            promozioni se utilizzi gmail)
          </h3>
          <h3 v-if="success && eng" class="success">
            Thank You! Your first adventure has just begun, <br /><br />
            Make sure to double check your mailbox (such as "promotions" tab if
            you use gmail)
          </h3>
        </div>
      </div>
      <!-- <div class="img mob"></div> -->
    </div>
  </div>
</template>

<script>
import MarqueeText from "vue-marquee-text-component";
// import MailchimpSubscribe from "vue-mailchimp-subscribe";
// import axios from 'axios'
import jsonp from "jsonp";

export default {
  name: "Home",
  components: {
    MarqueeText,
    // MailchimpSubscribe,
  },
  data() {
    return {
      eng: false,
      success: false,
      mute: true,
      loading: false,
      error: null,
      email: ''
    };
  },
  methods: {
    SwitchLang() {
      this.eng = !this.eng;
    },
    Success() {
      this.success = true;
    },

    Play() {
      const audio = document.querySelector("audio");
      if (this.mute) {
        audio.play();
        this.mute = false;
      } else {
        audio.pause();
        this.mute = true;
      }
    },

    onResponse(data, error) {
      console.log(data)
      console.log(error)

      if(error.result === "success"){
                this.success = true;
        this.email = null;
      } else {
        this.error = error.msg
        if(!error.msg){
          this.error = "Ops! Please try later"
        }
      }
      // if (error) {
      //   this.error = error;
      // }

      // if (data && data.result === "error") {
      //   this.error = data.msg;
      // }

      // if (this.error) {
      //   console.log(this.error);
      // } else {
      //   this.success = true;
      //   this.email = null;
      //   // console.log("Sucesss");
      // }
    },

    subscribe(){
      this.loading = true
    const url =  `https://radiosafari.us7.list-manage.com/subscribe/post-json?EMAIL=${this.email.trim()}&id=eb69b87b08&u=adcc73703af6db6e9581dcb88&group[79963][${this.group}]=1`;
    jsonp(url, { param: "c" }, this.onResponse);
    this.loading = false
    }
  },
  computed:{
    group(){
      return this.eng ? 1 : 2;
    }
  },
  mounted() {
    // const url =
    //   "https://radiosafari.us7.list-manage.com/subscribe/post-json?EMAIL=toot%40gggg.cg&id=eb69b87b08&u=adcc73703af6db6e9581dcb88&group[79963][2]=1";
    // jsonp(url, { param: "c" }, this.onResponse);

    var audio = document.querySelector("audio");
    var promise = document.querySelector("audio").play();
    if (promise !== undefined) {
      promise
        .then(() => {
          audio.play();
          this.mute = false;
        })
        .catch((error) => {
          this.mute = true;
          console.log(error);
        });
    }
  },
};
</script>
<style lang="scss" scoped>
* {
  transition: 500ms;
}

.home {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 auto;
  // min-height: calc(100vh - 6rem);
}
.marquee {
  font-family: Voyage;
  color: white;
  background: black;
  // width: 100vw;
  font-size: 2em;
  div {
    padding: 0.1em 1em 0.3em 1em;

    img {
      width: 100px;
    }
  }
}

.container {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr 1fr;
  margin: 5vh 10vw;

  @media screen and (max-width: 700px) {
    grid-template-rows: 1fr auto auto;
    grid-template-columns: 1fr 1fr;
  }
}

.about {
  font-family: Pangram;
  text-align: left;
  color: #000000dd;
  // width: 80%;
  padding-bottom: 3vh;

  @media screen and (max-width: 700px) {
    grid-column: 1 / span 2;
  }
}

.img {
  position: relative;
  width: 30vw;
  height: 30vw;
  border: 1px solid black;
  // border-radius: 2vw;
  overflow: hidden;
  justify-self: end;
  grid-row: 1 / span 2;
  grid-column: 2;
  background-image: url("/rs-pic.png");
  background-size: cover;

  @media screen and (max-width: 700px) {
    grid-column: 1 / span 2;
    grid-row: 3;
    justify-self: center;
    width: 100%;
    height: 80vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }

  .credit {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 0.9rem;
    color: white;
  }
}

.sub {
  @media screen and (max-width: 700px) {
    justify-self: center;
    grid-column: 1 / span 2;
  }
}

.pic {
  width: 100%;
  height: 100%;
  // float: right;
}

h4 {
  font-weight: 300;
  margin-bottom: 1rem;
}

.sub {
  align-self: end;
  @media screen and (max-width: 700px) {
    margin-bottom: 5vh;
    margin-top: 7vh;
  }
}

.success {
  font-family: Pangram;
  font-size: 1.5rem;
  font-weight: 600;
  // letter-spacing: 0.1rem;
  color: #000000dd;
}

input {
  font-family: Pangram;
  font-size: 14px;
  padding: 0.2em 0.5em;
  border: 1px solid black;
  border-radius: 0;
  margin: 0;
}

button {
  font-family: Pangram;
  background: black;
  color: white;
  font-size: 14px;
  padding: 0.2em 0.5em;
  border: 1px solid black;
  margin: 0;
  //border-top: 2px solid black;
}

.lang {
  width: 100%;
  padding: 0 10vw;
  height: 5vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
  display: flex;
  justify-content: space-between;
}
.butt {
  // width: 1em;
  font-size: 0.9em;
  cursor: pointer;
  right: 0;
  text-align: right;
  padding: 0.5em 1em;
  border: 1px solid black;
  display: flex;

  color: black;

  &:hover {
    background: black;
    color: white;
  }
}
</style>

